import React from 'react';
import blog4 from '../../../Assets/blog/blog_4.png';
import './Blog.css'

const blogContent = {
  title: "Understanding BBPS and Its Impact in Rural India",
  sections: [
    {
      type: "paragraph",
      text: "The Bharat Bill Payment System (BBPS) is a centralized platform developed by the National Payments Corporation of India (NPCI) to enable secure, accessible, and efficient bill payments for Indian citizens. Initially launched in 2017, the BBPS allows users to pay utility bills, such as electricity, water, gas, and telecom bills, through both online and offline channels. It has the potential to transform payment systems, especially in rural India, by providing a seamless method of handling bill payments, which has traditionally been a significant barrier for people in rural areas due to a lack of access to banking and payment facilities."
    },
    {
      type: "header",
      level: 2,
      text: "Key Features of BBPS"
    },
    {
      type: "list",
      items: [
        { title: "Unified Payment Interface", text: "It provides a standardized platform where all bill payments are consolidated, creating a unified interface for consumers." },
        { title: "Multiple Payment Channels", text: "Payments can be made through various modes, including bank branches, ATMs, online platforms, and retail agents." },
        { title: "Accessibility", text: "BBPS is available through both physical and digital channels, making it accessible to a wider population, especially in rural areas." }
      ]
    },
    {
      type: "header",
      level: 2,
      text: "Impact of BBPS in Rural India"
    },
    {
      type: "list",
      items: [
        { title: "Improved Access to Utility Payments in Rural Areas", text: "One of the major challenges in rural India is the limited access to reliable and convenient ways to pay utility bills. BBPS has bridged this gap by offering multiple avenues for bill payments, including agent networks and digital inclusion." },
        { title: "Enhancing Financial Inclusion", text: "BBPS has played a significant role in promoting financial inclusion in rural India, enabling people to use non-bank payment channels and fostering a culture of digital literacy." },
        { title: "Boosting Local Business and Economic Activity", text: "BBPS is beneficial to small businesses and local service providers by reducing cash handling and increasing transparency, thereby improving the local economy." }
      ]
    },
    {
      type: "header",
      level: 2,
      text: "Challenges and Areas for Improvement"
    },
    {
      type: "list",
      items: [
        { title: "Awareness and Adoption", text: "Greater awareness and education about BBPS in rural areas are needed to improve adoption rates." },
        { title: "Connectivity Issues", text: "Inconsistent internet connectivity poses challenges in remote regions, affecting the adoption of BBPS." }
      ]
    },
    {
      type: "header",
      level: 2,
      text: "Conclusion: The Way Forward for BBPS in Rural India"
    },
    {
      type: "paragraph",
      text: "BBPS has emerged as a critical tool in advancing financial inclusion and convenience for rural citizens, offering a reliable solution for utility bill payments. Through the involvement of Banking Correspondents, mobile payment systems, and retail networks, BBPS is significantly enhancing access to essential services in rural India. However, continuous efforts in improving digital literacy, infrastructure, and connectivity will be necessary to further expand its reach and effectiveness in these areas."
    }
  ],
  sources: [
    "National Payments Corporation of India (2022). BBPS Overview and Impact.",
    "India Financial Inclusion Report (2023). Financial Inclusion and Digital Payments in Rural India.",
    "RBI Report (2021). Financial Inclusion in Rural India: Challenges and Opportunities.",
    "National Payments Corporation of India (2023). BBPS and Digital Payments Growth."
  ]
};

const Blog4 = () => {
  return (
    <div style={{ padding: "20px 0px", margin: "0 auto" }}>
      <div className="read-more-container">
        <div className="read-more-main-container d-flex align-items-center justify-content-center">
          <div className="header text-center text-white" style={{ width: 800 }}>
            <h1>{blogContent.title}</h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-center pt-3'>
        {/* <img alt='' src={blog4} width={600} height={500} /> */}
      </div>

      <div className='blogs_main'>
        {blogContent.sections.map((section, index) => {
          if (section.type === "paragraph") {
            return <p key={index}>{section.text}</p>;
          } else if (section.type === "header") {
            const Tag = `h${section.level}`;
            return <Tag key={index}>{section.text}</Tag>;
          } else if (section.type === "list") {
            return (
              <div key={index}>
                <ul>
                  {section.items.map((item, idx) => (
                    <li key={idx}>
                      <strong>{item.title}:</strong> <p>{item.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
          return null;
        })}

        <h3>Sources:</h3>
        <ul>
          {blogContent.sources.map((source, index) => (
            <li key={index}>{source}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Blog4;
