import React, { useRef } from 'react';
import './Home.css';
import InnerImg from '../../Landing/Home/Assets/inner_img.jpg';
import { useTypewriter } from 'react-simple-typewriter';
import Bank from './Assets/bank.png';
import Payment from './Assets/payment (1).png';
import Agreement from './Assets/agreement.png';
import Certificate from './Assets/certificate.png';
import Travel from '../Home/Assets/ticket.svg';
import Insurance from '../../../Assets/icons/courier.svg';
import { ThirdSection } from '../ThirdSection/ThirdSection';
import { FourthSection } from '../FourthSection/FourthSection';
import { SevenSection } from '../SevenSection/SevenSection';
import { TenthSection } from '../TenthSection/TenthSection';
import RoeSection from '../RoeSection/RoeSection';
import { HomePageData } from '../../Data/HomePageData';
import { GetTranlatedValue, GetWordDictonary } from '../../Data/LanguageContext';

const Home = () => {
  const thirdSectionRef = useRef(null);

  const scrollToRef = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  };

  const [text] = useTypewriter({
    words: GetWordDictonary(),
    loop: true,
    typeSpeed: 120,
    deleteSpeed: 50,
  });

  return (
    <>
      <div className="home-container pb-3">
        <div className="background-circle d-flex align-items-center">
          <div className="container position-relative" style={{ paddingTop: 75 }}>
            <div className="row m-0 d-flex align-items-center large-devices">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 p-0">
                <div className="d-flex flex-column csp-main-heading-h1">
                  <div className="m-0 p-0">
                    <div className="home-csp-heading">
                      <h1>
                        {GetTranlatedValue(HomePageData?.FIRSTSECTION?.mainheading)}
                        <br />
                        {GetTranlatedValue(HomePageData?.FIRSTSECTION?.mainheading2)}
                      </h1>
                    </div>
                    <div className="typewriter_heading d-flex align-items-center text">
                      <h1>{text}</h1>
                    </div>
                  </div>
                  <p className="mt-4" style={{ color: '#212529' }}>
                    {GetTranlatedValue(HomePageData?.FIRSTSECTION?.mainheading1)}
                  </p>
                  <ul className="p-0" style={{ listStyleType: 'none' }}>
                    {HomePageData.FIRSTSECTION.homelist.map((sec, i) => (
                      <li
                        key={i}
                        className="d-flex align-items-center"
                        style={{ color: '#00B087', gap: 20, fontWeight: 600 }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-check-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"></path>
                        </svg>
                        {GetTranlatedValue(sec.title)}
                      </li>
                    ))}
                  </ul>
                  <div className="responsive-btn d-flex">
                    <a
                      href={`${window.location.origin}/version2/auth/registration`}
                      style={{ textDecoration: 'none' }}
                    >
                      <button
                        style={{ backgroundColor: '#2c5363' }}
                        className="btn mb-2 csp-home-btn d-flex align-items-center px-3 px-lg-4 rounded-pill"
                      >
                        {GetTranlatedValue(HomePageData?.FIRSTSECTION?.getstarted)}
                        <span className="d-flex home-btn-arrow" style={{ transitionDuration: '0.3s' }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center home_rotate_container justify-content-center">
                <div id="rotating_container">
                  {[Bank, Payment, Agreement, Certificate, Travel, Insurance].map((img, i) => (
                    <div className="home-circle-item" style={{ '--d': `${-i * 9}s` }} key={i}>
                      <img className="circle_imgs" src={img} alt={`icon-${i}`} />
                    </div>
                  ))}
                </div>
                <img
                  className="home_bg_circle m-0 p-0"
                  src={InnerImg}
                  alt="inner"
                  loading="lazy"
                  style={{ borderRadius: '100%' }}
                />
              </div>
            </div>
            <div className="position-absolute scroll-down-btn">
              <button
                className="btn border-0 shadow-none d-flex align-items-center"
                style={{ gap: 10 }}
                onClick={() => scrollToRef(thirdSectionRef)}
              >
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                    <circle cx="9.5" cy="9.5" r="9.5" fill="#efefef"></circle>
                    <svg x="6" y="5" xmlns="http://www.w3.org/2000/svg" width="6.519" height="7.838" viewBox="0 0 6.519 7.838">
                      <g transform="translate(6.519) rotate(90)">
                        <path
                          d="M5.682,3.872H.5a.444.444,0,0,1-.5-.5c0-.092,0-.184,0-.275a.42.42,0,0,1,.451-.448H5.664C5.637,2.618,5.62,2.6,5.6,2.58L4.062,1.037a.444.444,0,0,1,0-.7C4.13.27,4.2.2,4.264.137a.427.427,0,0,1,.628,0Q6.3,1.54,7.7,2.945a.43.43,0,0,1-.02.65L5.11,6.164,4.9,6.369a.433.433,0,0,1-.656,0l-.206-.205a.427.427,0,0,1,0-.654L5.616,3.942c.019-.019.036-.039.066-.07"
                          fill="#b1b1b1"
                        ></path>
                      </g>
                    </svg>
                  </svg>
                </span>
                <span style={{ fontWeight: 500, color: '#a9a9a9' }}>
                  {GetTranlatedValue(HomePageData?.FIRSTSECTION?.scrolldown)}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <ThirdSection ref={thirdSectionRef} />
      <TenthSection />
      <FourthSection />
      <RoeSection />
      <SevenSection />
    </>
  );
};

export default Home;
