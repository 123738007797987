import React from 'react';

const MerchantAgreement = () => {
  // Data for prohibited and restricted goods
  const prohibitedGoods = [
    {
      category: "Which products are prohibited/dangerous to ship via CSP",
      items: [
        "Oil-based paint and thinners (flammable liquids)",
        "Industrial solvents",
        "Insecticides, garden chemicals (fertilizers, poisons)",
        "Lithium batteries",
        "Magnetized materials",
        "Machinery (chain saws, outboard engines containing fuel or that have contained fuel)",
        "Fuel for camp stoves, lanterns, torches or heating elements",
        "Automobile batteries",
        "Infectious substances",
        "Any compound, liquid or gas that has toxic characteristics",
        "Bleach",
        "Flammable adhesives",
        "Arms and ammunition",
        "Dry ice (Carbon Dioxide, Solid)",
        "Any aerosols, liquids and/or powders or any other flammable substances classified as Dangerous Goods for transport by Air"
      ]
    }
  ];

  const restrictedItems = [
    {
      category: "Restricted Items",
      items: [
        "Precious stones, gems, and jewelry",
        "Uncrossed (bearer) drafts / cheque, currency, and coins",
        "Poison",
        "Firearms, explosives, and military equipment",
        "Hazardous and radioactive material",
        "Foodstuff and liquor",
        "Any pornographic material",
        "Hazardous chemical items"
      ]
    }
  ];

  const additionalNotes = [
    "The user may have to pay hefty penalties.",
    "No member of CSP will be responsible and liable for any loss, damage, theft, or misappropriation of such products."
  ];

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        {prohibitedGoods.map((category, index) => (
          <div key={index}>
            <h2 style={styles.category}>{category.category}</h2>
            <ul style={styles.list}>
              {category.items.map((item, i) => (
                <li key={i} style={styles.item}>{item}</li>
              ))}
            </ul>
          </div>
        ))}

        {restrictedItems.map((category, index) => (
          <div key={index}>
            <h2 style={styles.category}>{category.category}</h2>
            <ul style={styles.list}>
              {category.items.map((item, i) => (
                <li key={i} style={styles.item}>{item}</li>
              ))}
            </ul>
          </div>
        ))}

        <h2 style={styles.notesHeader}>Notes</h2>
        <ul style={styles.list}>
          {additionalNotes.map((note, i) => (
            <li key={i} style={styles.item}>{note}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '20px',
    marginTop:80,
    backgroundColor: '#f5f5f5', 
  },
  content: {
    textAlign: 'center',
    backgroundColor: '#fff', 
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  category: {
    fontSize: '20px',
    marginTop: '20px',
  },
  list: {
    listStyleType: 'disc', 
    padding: '0 20px', 
    textAlign: 'left', 
  },
  item: {
    margin: '8px 0',
    fontSize: '16px',
  },
  notesHeader: {
    fontSize: '20px',
    marginTop: '30px',
    fontWeight: 'bold',
  },
};

export default MerchantAgreement;
