import React from 'react'
import { Link } from 'react-router-dom'
import './Terms.css'

const Terms = () => {
    return (
        <div className='terms-container px-3'>
            <div className='terms row'>
                <h1 className='text-center'>
                    Terms And Conditions
                </h1>
                <p>This Website is created and operated by M/s CSP INDIA NETWORK PVT LTD, (hereinafter referred to as “We”, “Our”, and “Us”) having its registered address at Corp.Off 207, City Avenue by Kolte Patil, near Range Rover ShowRoom, Wakad. and operating under the brand name “CSP” (“Brand Name”). We intend to ensure your steady commitment to the usage of this Website and the services provided by us through our website www.csp.in {" "} (hereinafter referred to as the “Platform”).</p>

                <p> For the purpose of these Terms of Service (“Terms of Service”), wherever the context so requires,</p>

                <p>   “We”, “Our”, and “Us” shall mean and refer to the Company or Website.</p>

                <p>“You”, “Your”, “Yourself”, or “User” shall mean and refer to natural and legal individuals who shall be users of this Platform provided by us and who are competent to enter into binding contracts, as per law.</p>

                <p>“Third Parties” refer to any Mobile Application, platform, or individual apart from the Users and the Company.</p>



                <b>  GENERAL TERMS</b>
                <ol className='row'>
                    <li>  The headings of each section in these Terms of Service are only for the purpose of organizing the various provisions under these Terms of Service in an orderly manner and shall be used by you to interpret the provisions contained herein in a manner as may apply to you. Further, it is specifically agreed by you that the headings shall have legal or contractual value on your usage of the Platform.</li>
                    <li> The use of this Platform is solely governed by these Terms of Service and Privacy Policy that may be uploaded on the Platform and any modifications or amendments made thereto by us from time to time, at our sole discretion. If you as a user continue to access and use this Platform, you are agreeing to comply with and be bound by the following Terms of Service and Privacy Policy.</li>
                    <li> You expressly agree and acknowledge that these Terms of Service and Privacy Policy are coterminous in nature and that expiry/termination of either one will lead to the termination of the other.</li>
                    <li> You unequivocally agree that these Terms of Service and Privacy Policy constitute a legally binding agreement between us, that you shall be subject to the rules, guidelines, policies, terms, and conditions applicable to your use of the Platform, and that the same shall be deemed to be incorporated into these Terms of Service and shall be treated as part and parcel of the same. You acknowledge and agree that no signature or express act is required to make these Terms of Service and Privacy Policy binding on you and that your act of visiting or downloading any part of the Platform constitutes your full and final acceptance of these Terms of Service and Privacy Policy.</li>
                    <li> We reserve the sole and exclusive right to amend or modify these Terms of Service and Privacy Policy without any prior permission or intimation to you and you expressly agree that any such amendments or modifications shall come into effect immediately. If you do not adhere to the changes, you must stop using the Platform at once. Your continued use of the Platform will signify your acceptance of the changed terms.</li>
                </ol>




                <b> PLATFORM OVERVIEW</b>

                <p>  CSP is a Company engaged in digital aggregator platform that offers multiple customer and citizen-centric services. The Platform will serve as a Partner to individuals and entities interested in setting up their own customer service setup, and can partner with CSP, to avail the multiple services available on our Platform.</p>

                <p>  Individuals. Entities and Third parties interested in offering the services that are listed on our Platform can Log in and register themselves, agree to this Terms and Conditions (through an OTP).</p>

                <p> The Platform also has integrated various Application Programming Interfaces (API’s) as well as payment gateway services on the platform which are put to use while using the services.</p>

                <p> The list of services may include but not limited to Banking, Government services, Bill payment, Utility Services and Legal services.</p>




                <b>REGISTRATION</b>

                <p> Registration for the Platform is mandatory in order to use the Platform. However, facility of linking of accounts on the Platform to Facebook and Google Plus accounts is not available.</p>

                <p> The Users shall register by the way of logging in to get access to all services and features provided by the Platform. The following information shall be collected from the User:</p>
                <ol>
                    <li>   Name</li>
                    <li>  Date Of Birth</li>
                    <li>  Phone Number</li>
                    <li> Residential Address</li>
                    <li> Geographical Location</li>
                    <li>  KYC Documents</li>
                    <li>   Email id</li>
                    <li>   Address</li>
                </ol>



                <b>  ELIGIBILITY</b>

                <p> You represent and warrant that you are competent and eligible to enter into legally binding agreements and of age and that you have the requisite authority to bind yourself to these Terms of Service following the Law. However, if you are a minor using this Platform, you may do so with the consent of your legal guardian. All Acts of minors shall be deemed to be considered as acts undertaken under the supervision of their legal guardian.
                    You further represent that you will comply with these Terms of Service and all applicable local, state, national and international laws, rules, and regulations.
                    You shall not use the Platform if you are not competent to contract or are disqualified from doing so by any other applicable law, rule, or regulation currently in force.
                </p>



                <b> PAYMENT GATEWAY</b>

                <p> For making all payments for services and Products on the Platform, you will be redirected to a third-party payment gateway that we may have an agreement with. The payment gateway may additionally seek information from you to process the payment for your purchase and additionally may charge you the payment gateway charges that may be applicable. The payment gateway consists of Debit Card/Credit Card/Net Banking/UPI and other wallet options. You shall be governed under the concerned payment gateway’s Terms and Conditions and other Policies for the purpose of all payment-related aspects.</p>





                <b>CONTENT</b>
                <ol className='row'>
                    <li> All text, graphics, write-ups, videos, User interfaces, visual interfaces, photographs, trademarks, logos, brand names, descriptions, sounds, music, and artwork (collectively, ‘Content’), is generated/provided or based on information provided by the users or third parties and We have no control and make no guarantees regarding the quality, the accuracy, integrity or genuineness of such content or such other information provided on the Platform.</li>
                    <li>All the Content displayed on the Platform is subject to copyright and shall not be reused by You (or a third party) without prior written consent from Us and the copyright owner.</li>
                    <li>You are solely responsible for the integrity, authenticity, quality, and genuineness of the details shared on the Platform. While feedback and comments by You can be made via the Platform, We bear no liability whatsoever for any feedback or comments made by other Users or made in respect of any of the content on the Platform. Further, the Platform reserves its right to suspend the account of any User for an indefinite period to be decided at the discretion of the Platform or to terminate the account of any User who is found to have created or shared, or submitted any content or part thereof that is found to be untrue/inaccurate/misleading or offensive/vulgar. You shall be solely responsible for making good any financial or legal losses incurred through the creation/sharing/submission of Content or part thereof that is deemed to be untrue/inaccurate/misleading.</li>
                    <li>You have a personal, non-exclusive, non-transferable, revocable, limited privilege to access the content on the Platform. You shall not copy, adapt, or modify any content without written permission from Us.</li>
                </ol>



                <b>INDEMNITY</b>
                <ol className='row'>
                    <li> You agree to indemnify, defend and hold Us harmless, and our respective directors, officers, employees, and agents (collectively, “Parties“), from and against any losses, liabilities, claims, damages, demands, costs, and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of, result from, or maybe payable by, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed according to these Terms of Service. Further, You agree to hold Us harmless against any claims made by any third party due to, or arising out of, or in connection with:</li>
                    <ol style={{ 'list-style-type': 'lower-roman' }}>
                        <li>Your use of the Platform,</li>
                        <li>Your violation of these Terms of Service;</li>
                        <li>Your violation of any rights of another;</li>
                        <li>Your alleged improper conduct according to these Terms of Service;</li>
                        <li>Your conduct in connection with the Platform;</li>
                    </ol>

                    <ul className='row'>
                        <li>You agree to fully cooperate in indemnifying Us at your expense. You also agree not to settle with any party without consent from Us.</li>
                        <li>In no event shall we be liable to compensate You or any third party for any special, incidental, indirect, consequential, or punitive damages whatsoever, including those resulting from loss of use, data, or profits, whether or not foreseeable, and whether or not You had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with Your use of or access to the Platform and/or the Services or materials contained therein.</li>
                    </ul>
                </ol>


                <b> LIMITATION OF LIABILITY</b>
                <ol>
                    <li>We are not responsible for any consequences arising out of the following events:</li>
                    <li>If the Platform is inoperative/non-responsive due to any connectivity errors associated with the internet connection such as but not limited to slow connectivity, no connectivity, server failure;</li>
                    <li>If you have fed incorrect information or data or for any deletion of data;</li>
                    <li>If there is an undue delay or inability to communicate through email;</li>
                    <li>If there is any deficiency or defect in the Services managed by Us;</li>
                    <li>If there is a failure in the functioning of any other service provided by Us.</li>
                </ol>


                <ul className='row'>
                    <li>The Platform accepts no liability for any errors or omissions, on behalf of itself, or for any damage caused to You, Your belongings, or any third party, resulting from the use or misuse of the Platform or any service availed of by Us through the Platform. The service and any Content or material displayed on the service are provided without any guarantees, conditions, or warranties as to its accuracy, suitability, completeness, or reliability. We will not be liable to you for the unavailability or failure of the Platform.</li>
                    <li>You are to comply with all laws applicable to you or your activities, and with all Policies, which are hereby incorporated into this Terms of Service by reference.</li>
                    <li>The Platform expressly excludes any liability for any loss or damage that was not reasonably foreseeable by the Platform and which is incurred by you in connection with the Platform, including loss of profits; and any loss or damage incurred by you as a result of your breach of these Terms of Service.</li>
                    <li>To the fullest extent permitted by law, we shall not be liable to You or any other party for any loss or damage, regardless of the form of action or basis of any claim. You acknowledge and agree that your sole and exclusive remedy for any dispute with us is to terminate your use of the Platform.</li>
                </ul>



                <b>TERM</b>
                <ol className='row'>
                    <li>These Terms of Service shall continue to form a valid and binding contract between us and shall continue to be in full force and effect until you continue to access and use the Platform.</li>
                    <li>You may terminate your use of the Platform at any time, by deleting your account on the Platform.</li>
                    <li>We may terminate these Terms of Service and close your account at any time without notice and / or suspend or terminate Your access to the Platform at any time and for any reason, if any discrepancy or legal issue arises.</li>
                    <li>Such suspension or termination shall not limit our right to take any other action against you that we consider appropriate.</li>
                    <li> It is also hereby declared that we may discontinue the Services and Platform without any prior notice.</li>
                </ol>


                <b>TERMINATION</b>
                <ol className='row'>
                    <li>We reserve the right to unilaterally terminate Your access to the Platform, or any portion thereof, at any time, without notice or cause, by deleting your account on the Platform.</li>
                    <li>We also reserve the universal right to deny access to You, to any / all of our Services on our Platform without any prior notice / explanation to protect the interests of the Platform and / or other Users to the Platform.</li>
                    <li>We reserve the right to limit, deny or create different access to the Platform and its features concerning different Users, or to change any of the features or introduce new features without prior notice.</li>
                    <li>You shall continue to be bound by these Terms of Service, and it is expressly agreed to by You that You shall not have the right to terminate these Terms of Service till the expiry of the same.</li>
                </ol>


                <b>COMMUNICATION</b>
                <ol className='row'>
                    <li>By using this Platform and providing Your identity and contact information to Us through the Platform, You agree and consent to receive emails or SMS from Us and / or any of its representatives at any time.</li>
                    <li>You can report to “Business Manager” if you find any discrepancy with regard to Platform or content - related information and we will take necessary action after an investigation.The response with resolution(if any issues found) shall be dependent on the time taken for investigation.</li>
                    <li>You expressly agree that notwithstanding anything contained hereinabove, it may be contacted by Us relating to any services availed by You on the Platform or anything pursuant thereto and You agree to indemnify Us from any harassment claims.It is expressly agreed to by Us that any information shared by Us shall be governed by the Privacy Policy.</li>
                </ol>



                <b>USER OBLIGATIONS AND FORMAL UNDERTAKINGS TO CONDUCT</b>

                <p> You agree and acknowledge that you are a restricted user of this Platform and you:</p>
                <ol className='row'>
                    <li>Agree to provide genuine credentials during the process whenever required on the Platform.You shall not use a fictitious identity.We are not liable if you have provided incorrect information.</li>
                    <li>Agree to ensure the Name, Phone Number, Email id, address, gender and any such other information that may be provided is valid at all times and shall keep your information accurate and up - to - date.</li>
                    <li>You agree that You are solely responsible for maintaining the confidentiality of your account.You agree to notify us immediately of any unauthorized use of your account.We reserve the right to close your account at any time for any or no reason.</li>
                    <li>Understand and acknowledge that the data submitted is manually entered into the database of the Platform.You also acknowledge the fact that data so entered into the database is for easy and ready reference for You, and to streamline the Services through the Platform.</li>
                    <li>Authorize the Platform to use, store or otherwise process certain personal information and all published Content, responses, locations, User comments, reviews, and ratings for personalization of Services, marketing, and promotional purposes and for optimization of User - related options and Services.</li>
                    <li>Understand and agree that, to the fullest extent permissible by law, the Platform or any of their affiliates or their respective officers, directors, employees, agents, licensors, representatives, operational service providers, advertisers, or suppliers shall not be liable for any loss or damage, of any kind, direct or indirect, in connection with or arising from the use of the Platform or this terms of use, including, but not limited to, compensatory, consequential, incidental, indirect, special or punitive damages.</li>
                    <li>Are bound not to cut, copy, modify, recreate, reverse engineer, distribute, disseminate, post, publish or create derivative works from, transfer, or sell any information obtained from the Platform.Any such use / limited use of the Platform will only be allowed with prior express written permission.</li>
                    <li>Agree not to access(or attempt to access) the Platform and / or the materials or Services by any means other than through the interface provided by the Platform.The use of deep - link, robot, spider or other automatic devices, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Platform or its content, or in any way reproduce or circumvent the navigational structure or presentation of the Platform, materials or any content, or to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Platform will lead to suspension or termination of your access to the Platform.We disclaim any liabilities arising concerning such offensive content on the Platform.</li>
                    <li>Expressly agree and acknowledge that the Content generated by the Users and displayed on the Platform is not owned by Us and that We are in no way responsible for the content of the same.You may, however, report any offensive or objectionable content, which We may then remove from the Platform, at our sole discretion.</li>
                </ol>

                <p>You further undertake not to:</p>
                <ol className='row'>
                    <li>Engage in any activity that interferes with or disrupts access to the Platform or the services provided therein(or the servers and networks which are connected to the Platform);</li>
                    <li>Impersonate any person or entity, or falsely state or otherwise misrepresent his / her affiliation with a person or entity;</li>
                    <li>Probe, scan, or test the vulnerability of the Platform or any network connected to the Platform, nor breach the security or authentication measures on the Platform or any network connected to the Platform.The User may not reverse look - up, trace or seek to trace any information relating to any other User of, or visitor to, the Platform, or any other viewer of the Platform, including any User account maintained on the Platform not operated / managed by the User, or exploit the Platform or information made available or offered by or through the Platform, in any manner;</li>
                    <li>Disrupt or interfere with the security of, or otherwise cause harm to, the Platform, systems resources, accounts, passwords, servers, or networks connected to or accessible through the Platform or any affiliated or linked Platforms.</li>
                    <li>Use the Platform or any material or content therein for any purpose that is unlawful or prohibited by these Terms of Service, or to solicit the performance of any illegal activity or other activity which infringes the rights of this Platform or any other third party(s);</li>
                    <li>Violate any code of conduct or guideline which may apply for or to any particular service offered on the Platform;</li>
                    <li>Violate any applicable laws, rules, or regulations currently in force within or outside India;</li>
                    <li>Violate any portion of these Terms of Service and Privacy Policy including but not limited to any applicable additional terms of the Platform contained herein or elsewhere, whether made by amendment, modification, or otherwise;</li>
                    <li>Commit any act that causes Us to lose(in whole or in part) the Services of its Internet Establishment(“ISP“) or in any manner disrupts the Services of any other supplier / service provider of the Platform;</li>
                    <li>You hereby expressly authorize Us to disclose any and all information relating to You in our possession to law enforcement or other government officials, as We may in our sole discretion, believe necessary or appropriate in connection with the investigation and / or resolution of possible crimes, especially those involve personal injury and theft / infringement of intellectual property.You further understand that the Platform might be directed to disclose any information(including the identity of persons providing information or materials on the Platform) as necessary to satisfy any judicial Order, law, regulation, or valid governmental request.</li>
                    <li>By indicating Your acceptance to use any services offered on the Platform, You are obligated to complete such transactions after making payment.You shall be prohibited from indicating their acceptance to avail services where the transactions have remained incomplete.</li>
                    <li>You agree to use the services provided by Us, our affiliates, consultants, and contracted companies, for lawful purposes only.</li>
                    <li>You agree to provide authentic and true information.We reserve the right to confirm and validate the information and other details provided by You at any point in time.If upon confirmation such details are found to be false, not to be true(wholly or partly), We shall in our sole discretion reject the order and debar You from using the Platform without prior intimation whatsoever.</li>
                    <li>You agree not to post any material on the Platform that is defamatory, offensive, obscene, indecent, abusive, or needlessly distressful or advertise any goods or services.More specifically, You agree not to host, display, upload, update, publish, modify, transmit, or in any manner share any information that:</li>
                    <li>belongs to another person and to which you have no right to;</li>
                    <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                    <li>is in any way harmful to minors;</li>
                    <li>infringes any patent, trademark, copyright, or other proprietary rights;</li>
                    <li>violates any law for the time being in force;</li>
                    <li> deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing;</li>
                    <li> Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</li>
                    <li> Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
                    <li> Publish, post, disseminate, any grossly harmful information, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to “indecent representation of women” within the meaning of the Indecent Representation of Women(Prohibition) Act, 1986;</li>
                    <li> Threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                </ol>




                <b>SUSPENSION OF USER ACCESS AND ACTIVITY</b>

                <p> Notwithstanding other legal remedies that may be available, we may in our sole discretion, limit your access and / or activity by immediately removing your access credentials either temporarily or indefinitely, or suspend / terminate your association with the Platform, and / or refuse to the usage of the Platform, without being required to provide you with notice or cause:</p>
                <ol>
                    <li>If you are in breach of any of these Terms of Service and Privacy Policy;</li>
                    <li>If you have provided wrong, inaccurate, incomplete or incorrect information;</li>
                    <li>If your actions may cause any harm, damage or loss to the other Users or Us, at our sole discretion.</li>
                </ol>




                <b> INTELLECTUAL PROPERTY RIGHTS</b>
                <ol className='row'>
                    <li>Unless expressly agreed to in writing, nothing contained herein shall give you a right to use any of the trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive brand features, save according to the provisions of these Terms of Service that shall be available on the Platform.All logos, trademarks, brand names, service marks, domain names, including material, designs, and graphics created by and developed by either the Platform or such other third party and other distinctive brand features of the Platform are the property of the Platform or the respective copyright or trademark owner.Furthermore, concerning the Platform, we shall be the exclusive owner of all the designs, graphics, and the like, related to the Platform.</li>
                </ol>
                <ul className='row'>
                    <li>You shall not use any of the intellectual property displayed on the Platform in any manner that is likely to cause confusion among existing or prospective users of the Platform, or that in any manner disparages or discredits the Platform, to be determined in the sole discretion.</li>
                    <li>You are aware of all intellectual property, including but not limited to copyrights, relating to said services resides with the owners, and that at no point does any such intellectual property stand transferred from the aforementioned creators.You are aware that we merely provide the Platform through which You can communicate with other users and the Platform does not own any of the intellectual property relating to the independent content displayed on the Platform, apart from created graphics and specified content.</li>
                    <li>You are further aware that any reproduction or infringement of the intellectual property of the aforementioned owners by You will result in legal action being initiated against You by the respective owners of the intellectual property so reproduced / infringed upon.It is agreed to by You that the contents of this section shall survive even after the termination or expiry of these Terms of Service and / or Privacy Policy.</li>
                </ul>



                <b>DISCLAIMER OF WARRANTIES AND LIABILITIES</b>
                <ol className='row'>
                    <li> You further agree and undertake that you are accessing the Platform at your sole risk and are using the best and prudent judgment before availing of any features on the Platform or accessing / using any information displayed thereon.</li>
                    <li> You agree that any kind of information, resources, activities, or recommendations obtained / availed from the Platform, written or oral, will not create any warranty and we disclaim all liabilities resulting from these.</li>
                    <li> We do not guarantee that the features and content contained in the Platform will be uninterrupted or error - free, or that the Platform or its server will be free of viruses or other harmful components, and You hereby expressly accept any associated risks involved with your use of the Platform.</li>
                    <li> It is further agreed to by You that the contents of this section shall survive even after the termination or expiry of the Terms of Service and Privacy Policy.</li>
                </ol>




                <b>FORCE MAJEURE</b>

                <p> We will not be liable for damages for any delay or failure to perform our obligations hereunder if such delay or failure is due to cause beyond our control or without its fault or negligence, due to Force Majeure events including but not limited to acts of war, acts of God, earthquake, riot, fire, festive activities sabotage, labour shortage or dispute, internet interruption, technical failure, breakage of sea cable, hacking, piracy, cheating, illegal or unauthorized.</p>



                <b>DISPUTE RESOLUTION AND JURISDICTION</b>

                <p>In the event of any dispute arising out of or in connection with this agreement, including any dispute relating to the validity of this agreement, the parties shall, at first instance, attempt to resolve the dispute by mediation administered by the Centre for Online Resolution of Disputes(“CORD”)(www.resolveoncord.com) and conducted in accordance with the CORD Rules of Mediation, by a sole mediator to be appointed by CORD.</p>

                <p>In the event the parties are unable to resolve any such dispute through mediation within 45 days of the initiation of the dispute, subject to a contrary agreement between the parties, the dispute shall be finally resolved by arbitration administered by CORD and conducted in accordance with the Rules of Arbitration of CORD, by a sole arbitrator to be appointed by CORD.The language of the arbitration shall be English.The seat of arbitration shall be, [], India.</p>

                <p>You expressly agree that the Terms of Service, Privacy Policy and any other agreements entered into between the Parties are governed by the laws, rules, and regulations of India.</p>




                <b>MISCELLANEOUS PROVISIONS</b>

                <ol className='row'>
                    <li><b>Entire Agreement:</b> These Terms of Service, read with the Privacy Policy form the complete and final contract between us with respect to the subject matter hereof and supersedes all other communications, representations, and agreements(whether oral, written or otherwise) relating thereto.</li>
                    <li><b>Waiver:</b> The failure at any time to require performance of any provision of these Terms of Service shall in no manner affect our right at a later time to enforce the same.No waiver by us of any breach of these Terms of Service, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms of Service.</li>
                    <li><b>Severability:</b> If any provision / clause of these Terms of Service is held to be invalid, illegal, or unenforceable by any court or authority of competent jurisdiction, the validity, legality, and enforceability of the remaining provisions / clauses of these Terms of Service shall in no way be affected or impaired thereby, and each such provision / clause of these Terms of Service shall be valid and enforceable to the fullest extent permitted by law.In such case, these Terms of Service shall be reformed to the minimum extent necessary to correct any invalidity, illegality or unenforceability, while preserving to the maximum extent the original rights, intentions and commercial expectations of the Parties hereto, as expressed herein.</li>
                    <li><b>Contact Us:</b> If you have any questions about these Terms of Service, the practices of the Platform, or your experience, you can contact us by emailing us at <Link>support@csp.in</Link> or by writing to us at:</li>
                </ol>



                <b>CSP INDIA NETWORK PVT LTD</b>

                <p>Corp.Off 207, City Avenue by Kolte Patil, near Range Rover ShowRoom, Wakad.</p>
            </div >
        </div >
    )
}

export default Terms