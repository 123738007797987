import React from 'react';
import blog4 from '../../../Assets/blog/customerservice.jpg';

const blogContent = {
  title: "Digitization, Growth of Internet, and Entrepreneurship in Rural India",
  sections: [
    {
      type: "paragraph",
      text: "Digitization Growth of Internet and Entrepreneurship in Rural India India’s digital transformation is increasingly evident in rural areas where access to the internet and technological tools has begun to reshape the entrepreneurial landscape. The rapid digitization fueled by government initiatives affordable mobile data and the growth of internet penetration has opened up new business avenues for rural entrepreneurs. This shift is empowering people from villages and small towns to access global markets obtain digital literacy and participate in India’s growing digital economy. In this article we explore the impact of digitization and internet growth on entrepreneurship in rural India supported by relevant data and sources."
    },
    {
      type: "header",
      level: 2,
      text: "1. The Digital Revolution in Rural India"
    },
    {
      type: "paragraph",
      text: "The adoption of digital technologies has been accelerated by various government policies and initiatives aimed at increasing internet penetration and digital literacy across rural India. As of 2023 India had approximately 900 million internet users and about 70% of these users were from rural areas as per the Ministry of Electronics and Information Technology (MeitY) report."
    },
    {
      type: "header",
      level: 2,
      text: "2. Internet Access and Its Impact on Entrepreneurship"
    },
    {
      type: "list",
      items: [
        {
          title: "E-Commerce Growth",
          text: "Platforms like Amazon India Flipkart and IndiaMart have become integral for rural entrepreneurship offering marketplaces to rural sellers."
        },
        {
          title: "Digital Payments and Financial Inclusion",
          text: "Growth of Unified Payments Interface (UPI) and mobile wallets has made business transactions easier for rural entrepreneurs."
        }
      ]
    },
    {
      type: "header",
      level: 2,
      text: "3. Empowering Rural Entrepreneurs with Skill Development"
    },
    {
      type: "paragraph",
      text: "The National Institute of Electronics and Information Technology (NIELIT) has provided digital literacy training to over 30 million rural citizens under the Digital Literacy Mission. Online platforms such as Coursera Udemy SWAYAM and PMGDISHA have become essential resources."
    },
    {
      type: "header",
      level: 2,
      text: "4. Challenges in Digital Adoption"
    },
    {
      type: "list",
      items: [
        {
          title: "Digital Literacy",
          text: "Approximately 30% of the rural population remains digitally illiterate limiting the use of digital tools."
        },
        {
          title: "Infrastructure",
          text: "Unreliable internet and electricity in rural areas present significant challenges."
        }
      ]
    },
    {
      type: "header",
      level: 2,
      text: "Conclusion"
    },
    {
      type: "paragraph",
      text: "The digitization of rural India is fostering a new wave of entrepreneurship. From online marketplaces to financial inclusion tools digital platforms are equipping rural entrepreneurs with the necessary tools to succeed."
    }
  ],
  sources: [
    "Ministry of Electronics and Information Technology (MeitY)",
    "Telecom Regulatory Authority of India (TRAI)",
    "National Payments Corporation of India (NPCI)",
    "National Institute of Electronics and Information Technology (NIELIT)"
  ]
};

const Blog3 = () => {
  return (
    <div style={{ padding: "20px 0px", margin: "0 auto" }}>
      <div className="read-more-container">
        <div className="read-more-main-container d-flex align-items-center justify-content-center">
          <div className="header text-center text-white" style={{ width: 800 }}>
            <h1>{blogContent.title}</h1>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-center pt-3'>
        <img alt='' src={blog4} width={600} height={500} />
      </div>

      <div style={{ padding: "50px 300px" }}>
        {blogContent.sections.map((section, index) => {
          if (section.type === "paragraph") {
            return <p key={index}>{section.text}</p>;
          } else if (section.type === "header") {
            const Tag = `h${section.level}`;
            return <Tag key={index}><p style={{fontSize:24, paddingTop:20}}>{section.text}</p> </Tag>;
          } else if (section.type === "list") {
            return (
              <div key={index}>
                <ul>
                  {section.items.map((item, idx) => (
                    <li key={idx}>
                      <strong>{item.title}:</strong> <p className='mb-0'>{item.text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            );
          }
          return null;
        })}

        <h3>Sources:</h3>
        <ul>
          {blogContent.sources.map((source, index) => ( 
            <li key={index}>{source}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Blog3;
